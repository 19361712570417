import { DialogService }      from 'aurelia-dialog';
import { inject }             from 'aurelia-framework';
import { BaseListViewModel }  from 'base-list-view-model';
import { BooleanOption }      from 'modules/administration/models/boolean-option';
import { FormSchema }         from 'modules/personal-area/messages/filter-form-schema';
import { MessagesRepository } from 'modules/personal-area/messages/services/repository';
import { InfoDialog }         from 'resources/elements/html-elements/dialogs/info-dialog';
import { AppContainer }       from 'resources/services/app-container';

@inject(AppContainer, DialogService, FormSchema, MessagesRepository)
export class ListMessages extends BaseListViewModel {

    listingId = 'personal-area-messages-listing';

    headerTitle = 'listing.personal-area.messages';

    //  COMMENTED FUTURE IMPLEMENTATION MAY NEED THIS-
    newRecordRoute = 'personal-area.messages.create';

    repository;
    datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogService
     * @param formSchema
     * @param repository
     */
    constructor(appContainer, dialogService, formSchema, repository) {
        super(appContainer);

        this.dialogService = dialogService;
        this.formSchema    = formSchema;
        this.repository    = repository;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'personal-area.messages.manage',
            'personal-area.messages.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.defineDatatable();
        this.filterSchema = this.formSchema.schema(this);
        this.filterModel  = this.formSchema.model(this);
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:      this.repository,
            edit:            null,
            destroy:         null,
            destroySelected: false,
            actions:         [
                {
                    icon:    'icon-file-text',
                    action:  (row) => this.viewMessageHandler(row),
                    tooltip: 'form.button.view',
                },
            ],
            options:         [
                {
                    icon:    'icon-checkmark3',
                    tooltip: 'form.button.mark-selected-as-read',
                    label:   'form.button.mark-selected-as-read',
                    action:  () => this.markSelectedAsRead(),
                },
            ],
            buttons:         [],
            selectable:      (row) => row.addresees_ids.includes(this.appContainer.authenticatedUser.user.id),
            sorting:         {
                column:    1,
                direction: 'desc',
            },
            columns:         [
                {
                    data:  'subject',
                    name:  'message_translations.subject',
                    title: 'form.field.subject',
                },
                {
                    data:            'created_at',
                    name:            'messages.created_at',
                    title:           'form.field.sent-at',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
                {
                    data:  'addresser',
                    name:  'addressers.name',
                    title: 'form.field.sent-by',
                },
                {
                    data:  'addressees',
                    name:  'addressee_users.name',
                    title: 'form.field.addressees',
                },
                {
                    data:       'allows_reply',
                    name:       'boolean_options.name',
                    title:      'form.field.allows-reply',
                    type:       'stoplight',
                    color:      (row) => row.allows_reply === BooleanOption.NO ? 'text-danger' : 'text-success',
                    orderable:  false,
                    searchable: false,
                },
            ],
        };
    }

    /**
     * Handles view action button
     *
     * @param row
     */
    viewMessageHandler(row) {
        let route = this.appContainer.router.generate('personal-area.messages.details', { id: row.id });

        // marks the message has read
        this.repository.read(row.id).then(() => {
            this.appContainer.router.navigate(route);
        });
    }

    /**
     * Marks the selected records as read
     */
    markSelectedAsRead() {
        let selectedRows = this.datatable.instance.selectedRows;

        if (!selectedRows.length) {
            return this.dialogService.open({
                viewModel: InfoDialog,
                model:     {
                    body:  this.appContainer.i18n.tr('message.select-at-least-one-record'),
                    title: this.appContainer.i18n.tr('text.attention'),
                },
            });
        }

        this.repository
            .readSelected(selectedRows)
            .then((response) => {
                this.appContainer.notifier.responseNotice(response);

                if (response.status === true) {
                    this.datatable.instance.reload();
                }
            });
    }

}
