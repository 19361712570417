import { inject }          from 'aurelia-framework';
import { Message }         from 'modules/administration/models/message';
import { UsersRepository } from 'modules/administration/users/services/repository';

@inject(UsersRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {};

    /**
     * Constructor
     *
     * @param usersRepository
     */
    constructor(usersRepository) {
        this.usersRepository = usersRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {Message}
     */
    model() {
        let model = new Message();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {

        this.subject = {
            type:  'text',
            key:   'subject',
            label: 'form.field.subject',
            size:  12,
        };

        this.body = {
            type:  'textarea',
            key:   'body',
            label: 'form.field.message',
            size:  12,
        };

        this.addressees = {
            type:         'multiselect-native',
            key:          'addressees',
            label:        'form.field.addressees',
            size:         4,
            options:      [],
            remoteSource: this.usersRepository.active.bind(this.usersRepository),
        };

        this.allows_reply = {
            type:     'checkbox',
            key:      'allows_reply',
            required: false,
            size:     3,
            option:   {
                label: 'form.field.allows-reply',
            },
        };

        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     (event) => viewModel.redirectToRoute('personal-area.messages.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      'form.button.clear',
            action:     () => viewModel.resetForm(),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.send-message',
            action:     (event) => viewModel.submit(),
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-checkmark3',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.clearButton,
                this.submitButton,
            ],
        };

        return [
            [this.subject],
            [this.body],
            [this.addressees, this.allows_reply],
            [this.buttons],
        ];
    }

}
